import { default as _91_46_46_46cms_934Ax7DVmfCoMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93h5qK8JcTVaMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/3d-projects/[...slug].vue?macro=true";
import { default as indexLkXR8gtdNAMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/3d-projects/index.vue?macro=true";
import { default as _91_46_46_46slug_93DLr8Wmq1SaMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/akcii/[...slug].vue?macro=true";
import { default as indexHJh2nMclb1Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/akcii/index.vue?macro=true";
import { default as _91_46_46_46slug_93ybHJ7awEQpMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/articles/[...slug].vue?macro=true";
import { default as indexqrPkeEcXGpMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/articles/index.vue?macro=true";
import { default as indexymaSRgwhzRMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/authorization/index.vue?macro=true";
import { default as indexiv2so5A9lZMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/cart/index.vue?macro=true";
import { default as indexqeZoYIRIOhMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93Uta5A2LLZyMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/catalog/[...slug].vue?macro=true";
import { default as indexBwFmabnqo9Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_938VY4j53N35Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/catalogsearch/[...slug].vue?macro=true";
import { default as resultaRhgpSGyIuMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/catalogsearch/result.vue?macro=true";
import { default as indexyOeVi9Zu6VMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/clear-page-slider/index.vue?macro=true";
import { default as _91_46_46_46slug_93CQvY2MvCgcMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/collection/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93uslYp3wu5mMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/fabriki/[...slug].vue?macro=true";
import { default as index4um3kCB7McMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/fabriki/index.vue?macro=true";
import { default as _91_46_46_46slug_93fsonnLkgbAMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/product/[...slug].vue?macro=true";
import { default as indexZUa3D8cEGEMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/profile/index.vue?macro=true";
import { default as indexSQeHn2mLEtMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/profile/orders/index.vue?macro=true";
import { default as indexcUIibI5MVqMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/recently/index.vue?macro=true";
import { default as _91_46_46_46slug_93f9lkfxGkxEMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/sets/[...slug].vue?macro=true";
import { default as index98API66zzjMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_934Ax7DVmfCoMeta?.name ?? "cms",
    path: _91_46_46_46cms_934Ax7DVmfCoMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_934Ax7DVmfCoMeta || {},
    alias: _91_46_46_46cms_934Ax7DVmfCoMeta?.alias || [],
    redirect: _91_46_46_46cms_934Ax7DVmfCoMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93h5qK8JcTVaMeta?.name ?? "3d-projects-slug",
    path: _91_46_46_46slug_93h5qK8JcTVaMeta?.path ?? "/3d-projects/:slug(.*)*",
    meta: _91_46_46_46slug_93h5qK8JcTVaMeta || {},
    alias: _91_46_46_46slug_93h5qK8JcTVaMeta?.alias || [],
    redirect: _91_46_46_46slug_93h5qK8JcTVaMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/3d-projects/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexLkXR8gtdNAMeta?.name ?? "3d-projects",
    path: indexLkXR8gtdNAMeta?.path ?? "/3d-projects",
    meta: indexLkXR8gtdNAMeta || {},
    alias: indexLkXR8gtdNAMeta?.alias || [],
    redirect: indexLkXR8gtdNAMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/3d-projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DLr8Wmq1SaMeta?.name ?? "akcii-slug",
    path: _91_46_46_46slug_93DLr8Wmq1SaMeta?.path ?? "/akcii/:slug(.*)*",
    meta: _91_46_46_46slug_93DLr8Wmq1SaMeta || {},
    alias: _91_46_46_46slug_93DLr8Wmq1SaMeta?.alias || [],
    redirect: _91_46_46_46slug_93DLr8Wmq1SaMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/akcii/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexHJh2nMclb1Meta?.name ?? "akcii",
    path: indexHJh2nMclb1Meta?.path ?? "/akcii",
    meta: indexHJh2nMclb1Meta || {},
    alias: indexHJh2nMclb1Meta?.alias || [],
    redirect: indexHJh2nMclb1Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/akcii/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ybHJ7awEQpMeta?.name ?? "articles-slug",
    path: _91_46_46_46slug_93ybHJ7awEQpMeta?.path ?? "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93ybHJ7awEQpMeta || {},
    alias: _91_46_46_46slug_93ybHJ7awEQpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ybHJ7awEQpMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexqrPkeEcXGpMeta?.name ?? "articles",
    path: indexqrPkeEcXGpMeta?.path ?? "/articles",
    meta: indexqrPkeEcXGpMeta || {},
    alias: indexqrPkeEcXGpMeta?.alias || [],
    redirect: indexqrPkeEcXGpMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexymaSRgwhzRMeta?.name ?? "authorization",
    path: indexymaSRgwhzRMeta?.path ?? "/authorization",
    meta: indexymaSRgwhzRMeta || {},
    alias: indexymaSRgwhzRMeta?.alias || [],
    redirect: indexymaSRgwhzRMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexiv2so5A9lZMeta?.name ?? "cart",
    path: indexiv2so5A9lZMeta?.path ?? "/cart",
    meta: indexiv2so5A9lZMeta || {},
    alias: indexiv2so5A9lZMeta?.alias || [],
    redirect: indexiv2so5A9lZMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexqeZoYIRIOhMeta?.name ?? "cart-success",
    path: indexqeZoYIRIOhMeta?.path ?? "/cart/success",
    meta: indexqeZoYIRIOhMeta || {},
    alias: indexqeZoYIRIOhMeta?.alias || [],
    redirect: indexqeZoYIRIOhMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Uta5A2LLZyMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93Uta5A2LLZyMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93Uta5A2LLZyMeta || {},
    alias: _91_46_46_46slug_93Uta5A2LLZyMeta?.alias || [],
    redirect: _91_46_46_46slug_93Uta5A2LLZyMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexBwFmabnqo9Meta?.name ?? "catalog",
    path: indexBwFmabnqo9Meta?.path ?? "/catalog",
    meta: indexBwFmabnqo9Meta || {},
    alias: indexBwFmabnqo9Meta?.alias || [],
    redirect: indexBwFmabnqo9Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_938VY4j53N35Meta?.name ?? "catalogsearch-slug",
    path: _91_46_46_46slug_938VY4j53N35Meta?.path ?? "/catalogsearch/:slug(.*)*",
    meta: _91_46_46_46slug_938VY4j53N35Meta || {},
    alias: _91_46_46_46slug_938VY4j53N35Meta?.alias || [],
    redirect: _91_46_46_46slug_938VY4j53N35Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/catalogsearch/[...slug].vue").then(m => m.default || m)
  },
  {
    name: resultaRhgpSGyIuMeta?.name ?? "catalogsearch-result",
    path: resultaRhgpSGyIuMeta?.path ?? "/catalogsearch/result",
    meta: resultaRhgpSGyIuMeta || {},
    alias: resultaRhgpSGyIuMeta?.alias || [],
    redirect: resultaRhgpSGyIuMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/catalogsearch/result.vue").then(m => m.default || m)
  },
  {
    name: indexyOeVi9Zu6VMeta?.name ?? "clear-page-slider",
    path: indexyOeVi9Zu6VMeta?.path ?? "/clear-page-slider",
    meta: indexyOeVi9Zu6VMeta || {},
    alias: indexyOeVi9Zu6VMeta?.alias || [],
    redirect: indexyOeVi9Zu6VMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/clear-page-slider/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93CQvY2MvCgcMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93CQvY2MvCgcMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93CQvY2MvCgcMeta || {},
    alias: _91_46_46_46slug_93CQvY2MvCgcMeta?.alias || [],
    redirect: _91_46_46_46slug_93CQvY2MvCgcMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93uslYp3wu5mMeta?.name ?? "fabriki-slug",
    path: _91_46_46_46slug_93uslYp3wu5mMeta?.path ?? "/fabriki/:slug(.*)*",
    meta: _91_46_46_46slug_93uslYp3wu5mMeta || {},
    alias: _91_46_46_46slug_93uslYp3wu5mMeta?.alias || [],
    redirect: _91_46_46_46slug_93uslYp3wu5mMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/fabriki/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index4um3kCB7McMeta?.name ?? "fabriki",
    path: index4um3kCB7McMeta?.path ?? "/fabriki",
    meta: index4um3kCB7McMeta || {},
    alias: index4um3kCB7McMeta?.alias || [],
    redirect: index4um3kCB7McMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/fabriki/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93fsonnLkgbAMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93fsonnLkgbAMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93fsonnLkgbAMeta || {},
    alias: _91_46_46_46slug_93fsonnLkgbAMeta?.alias || [],
    redirect: _91_46_46_46slug_93fsonnLkgbAMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexZUa3D8cEGEMeta?.name ?? "profile",
    path: indexZUa3D8cEGEMeta?.path ?? "/profile",
    meta: indexZUa3D8cEGEMeta || {},
    alias: indexZUa3D8cEGEMeta?.alias || [],
    redirect: indexZUa3D8cEGEMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexSQeHn2mLEtMeta?.name ?? "profile-orders",
    path: indexSQeHn2mLEtMeta?.path ?? "/profile/orders",
    meta: indexSQeHn2mLEtMeta || {},
    alias: indexSQeHn2mLEtMeta?.alias || [],
    redirect: indexSQeHn2mLEtMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/profile/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexcUIibI5MVqMeta?.name ?? "recently",
    path: indexcUIibI5MVqMeta?.path ?? "/recently",
    meta: indexcUIibI5MVqMeta || {},
    alias: indexcUIibI5MVqMeta?.alias || [],
    redirect: indexcUIibI5MVqMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/recently/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93f9lkfxGkxEMeta?.name ?? "sets-slug",
    path: _91_46_46_46slug_93f9lkfxGkxEMeta?.path ?? "/sets/:slug(.*)*",
    meta: _91_46_46_46slug_93f9lkfxGkxEMeta || {},
    alias: _91_46_46_46slug_93f9lkfxGkxEMeta?.alias || [],
    redirect: _91_46_46_46slug_93f9lkfxGkxEMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/sets/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index98API66zzjMeta?.name ?? "wishlist",
    path: index98API66zzjMeta?.path ?? "/wishlist",
    meta: index98API66zzjMeta || {},
    alias: index98API66zzjMeta?.alias || [],
    redirect: index98API66zzjMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20250212120538/pages/wishlist/index.vue").then(m => m.default || m)
  }
]